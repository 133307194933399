html {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Amplify */
amplify-authenticator {
  --amplify-font-family: 'Noto Sans JP' 'Meiryo' 'Hiragino Kaku Gothic ProN' 'Hiragino Sans' 'Helvetica Neue' 'Arial'
    'sans-serif';
  --amplify-primary-color: #1976d2;
  --amplify-primary-tint: #1976d2;
  --amplify-primary-shade: #4171a1;

  --amplify-secondary-tint: #b40c0e;

  --amplify-text-xxs: 1.2rem;
  --amplify-text-xs: 1.2rem;
  --amplify-text-sm: 1.2rem;
  --amplify-text-md: 1.2rem;
  --amplify-text-md-sub: 1.4rem;
  --amplify-text-lg: 2.4rem;
  --amplify-text-xl: 3.2rem;
  --amplify-text-xxl: 4rem;

  --container-height: 0;
  --padding: 20px;
  --min-width: 300px;
  --width: 300px;
}
@media screen and (min-width: 672px) {
  amplify-authenticator {
    --min-width: 380px;
    --width: 380px;
  }
}
